<template>
  <el-divider border-style="dashed" />
  <div class="footer">
    <div class="container">
      <div class="content">
        <div class="right">
          <div class="row">
            <div class="col" v-for="(list, index) in Footlink" :key="index">
              <h3>{{ list.title }}</h3>
              <div
                class="pt-4"
                v-for="(item, index) in list.multiplejson"
                :key="index"
                @click="toUrl(item.bold_title)"
              >
                <h5>
                  <img src="@/assets/imgs/Layout/arrow5.png" alt="" />
                  {{ item.title }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-backtop :bottom="100">
      <div
        class="topCont"
        style="
          height: 100%;
          width: 100%;
          background-color: var(--el-bg-color-overlay);
          box-shadow: var(--el-box-shadow-lighter);
          text-align: center;
          color: #1989fa;
        "
      >
        <el-icon><Top /></el-icon>
        Top
      </div>
    </el-backtop>
  </div>
  <div class="lastFooter">
    <div class="lastFooter_text">
      <a
        @click="
          goToPage(
            'https://app.termly.io/document/privacy-policy/b6aa3570-28c5-4f8a-8e5b-6b80f8a30e07'
          )
        "
      >
        Privacy Policy
      </a>
      <a
        @click="
          goToPage(
            'https://app.termly.io/document/cookie-policy/f74b249c-10d8-4ee6-bce7-8c42474ca15c'
          )
        "
      >
        Cookie Policy</a
      >
      <a
        @click="
          goToPage(
            'https://app.termly.io/document/terms-of-service/e36a649e-f611-451f-b748-beb0aad411f3'
          )
        "
      >
        Terms and Conditions</a
      >
      <a class="tell" v-if="configData?.telephone">
        Tel：{{ configData?.telephone }}
      </a>
      <span>copyright @ 2018-2020 all rights reserved.</span>
      <div class="iconTop">
        <div
          class="iconImg"
          v-for="(item, index) in iconList"
          :key="index"
          @mouseenter="EnterArrowShow(index)"
          @mouseleave="LeaveArrowShow(index)"
          @click="OpenUrl(item.url)"
        >
          <img class="mark" :src="item.grey_image" />
        </div>
      </div>
      <router-link to="/">
        <img :src="configData?.website_logo" class="footer_logo" alt="" />
      </router-link>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import api from "@/utils/api";
import { getAllBanner } from "@/api/banner";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Top } from "@element-plus/icons-vue";

const store = useStore();
let configData = ref();
const router = useRouter();
let oldIcon = ref();
const EnterArrowShow = (index: any) => {
  oldIcon.value = iconList.value[index].grey_image;
  iconList.value[index].grey_image = iconList.value[index].colour_image;
};
const LeaveArrowShow = (index: any) => {
  iconList.value[index].grey_image = oldIcon.value;
};
const goToPage = (path: any) => {
  window.open(path, "_blank");
};
const isCookies = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src =
    "https://app.termly.io/resource-blocker/a4c635ac-d78a-4e47-9964-8688c0a3dbf1?autoBlock=on";
  document.head.appendChild(script);
};
let iconList = ref();
const getSetIcon = async () => {
  try {
    const res = await api.get("/common/icon");
    iconList.value = res.data;
  } catch (error) {
    console.error("请联系管理员", error);
  }
};
//获取底部链接
const Footlink = ref();
const getFootlink = async () => {
  try {
    const res = await getAllBanner("foot_link");
    Footlink.value = res.data;
  } catch (error) {
    console.error("请联系管理员", error);
  }
};
//这是点击跳转外部应用链接
const OpenUrl = (url: any) => {
  if (url) {
    window.open(url);
  } else {
    return;
  }
};
//这是底部跳转路由
const toUrl = (url: any) => {
  router.push({
    path: `${url}`,
  });
};

onMounted(async () => {
  await getSetIcon();
  await getFootlink();
  configData.value = store.state.configData;
  await isCookies();
});
</script>

<style scoped lang="scss">
.footer {
  width: 80.129vw;
  margin: 0 auto;
  margin-top: 40px;
  padding: 50px 0;
  color: #0044a0;
  box-sizing: border-box;
  .container {
    margin: 0 auto;
    .content {
      display: flex;
      justify-content: space-between;

      .right {
        width: 100%;

        .row {
          width: 100%;
          display: flex;
          justify-content: space-between;
          text-align: left;
          .col {
            text-decoration: none;
            color: inherit;
            padding-top: 34px;

            h5 {
              font-size: 20px;
              margin: 20px 0;
              font-weight: normal;
              border-bottom: 1px solid transparent;
              padding-bottom: 5px;
              cursor: pointer;
              &:hover {
                color: #1877f2;
                border-bottom: 1px solid #1877f2;
              }
            }
          }
          .pt-4 {
            p {
              margin: 10px 0;
            }
          }

          p {
            opacity: 0.5;
            width: 164px;
          }
        }
      }
    }
  }
  .el-backtop {
    width: 70px;
    height: 70px;
    .topCont {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
  .footer_logo {
    width: 120px;
  }
  p {
    opacity: 0.5;
  }
}
.lastFooter {
  width: 100%;
  height: 57px;
  border-top: 1px solid #f5f5f5;
  text-align: center;
  color: #0044a0;
  padding-top: 16px;
  .lastFooter_text {
    .iconTop {
      // width: 100%;
      .iconImg {
        float: left;
        margin-right: 0.5vw;
        cursor: pointer;
        // .arrowImg {
        //   width: 15px;
        //   height: 15px;
        // }
        .mark {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  a {
    cursor: pointer;
    // margin: 0 30px;
    &:hover {
      color: #1877f2;
      border-bottom: 1px solid #1877f2;
      padding-bottom: 3px;
    }
  }
}
h1 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  // color: var(--bs-heading-color);
}

////////////////////媒体查询pc端///////////////////////
@media screen and (min-width: 601px) {
  .footer {
    .container {
      .content {
        .right {
          .row {
            .col {
              h3 {
                font-size: 1.3vw;
              }
              .pt-4 {
                h5 {
                  font-size: 1.2vw;
                  img {
                    width: 1vw;
                    height: 1vw;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .lastFooter {
    .lastFooter_text {
      margin: 0 auto;
      width: 80.129vw;
      display: flex;
      align-items: center;
      justify-content: space-between;
      a,
      span {
        font-size: 1vw;
      }
      .iconTop {
        // display: none;
        .iconImg {
          .mark {
            width: 2vw;
            height: 2vw;
          }
        }
      }
      .footer_logo {
        display: none;
      }
      .tell {
        display: none;
      }
    }
  }
}
////////////////////媒体查询手机端/////////////////////
@media screen and (max-width: 600px) {
  .footer {
    display: none;
  }
  .lastFooter {
    height: auto;
    border: none;
    .lastFooter_text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      .iconTop {
        display: flex;
        justify-content: center;
        .iconImg {
          float: left;
          margin-right: 0.5vw;
          cursor: pointer;
          // .arrowImg {
          //   width: 15px;
          //   height: 15px;
          // }
          .mark {
            width: 40px;
            height: 40px;
          }
        }
      }
      a,
      span {
        margin: 10px 0;
      }
      .footer_logo {
        display: block;

        width: 120px;
      }
      .tell {
        display: block;
      }
    }
  }
}
</style>