<template>
  <div class="header-top" :id="HeadTop"></div>
  <div class="page-home" id="header">
    <div class="header">
      <div class="top">
        <div class="logo">
          <router-link to="/">
            <img :src="setList?.website_logo" alt="" />
          </router-link>
        </div>
        <div class="search">
          <el-input
            v-model="searchValue"
            class="w-50 m-2"
            size="large"
            placeholder="Search..."
            @change="changeSearch"
        style="height: 80%;"

          >
            <template #prefix>
              <el-icon class="el-input__icon" @click="changeSearch"
                ><Search
              /></el-icon>
            </template>
          </el-input>
          <span @click="openCn">CN</span>
        </div>
      </div>
      <div class="bottom">
        <el-menu
          class="el-menu-demo"
          mode="horizontal"
          :ellipsis="false"
          @select="handleSelect"
          router
          popper-offset="0"
        >
          <div class="flex-grow" />
          <el-sub-menu
            :index="meun.id"
            v-for="(meun,index) in MenuList"
            :key="meun.id"
            @click="open5G(meun.url)"
          >
            <template #title>
              <el-menu-item
                :index="
                  meun.route +
                  '?seo_title=' +
                  meun.seo_title +
                  '&seo_keywords=' +
                  meun.seo_keywords +
                  '&seo_description=' +
                  meun.seo_description
                "
              >
                {{ meun?.name }}
                <div class="line" v-if='index==3'>
                  <img
                    class="el-image"
                    :src="meun?.icon_image"
                    alt=""
                    srcset=""
                    style="width: 100%; height: 100%"
                  />
                </div>
              </el-menu-item>
            </template>
            <el-sub-menu
              v-for="muenChild in meun.childlist"
              :key="muenChild.id"
              :index="meun.id + '-' + muenChild.id"
            >
              <template #title>
                <el-menu-item
                  :index="
                    muenChild.route +
                    '?id=' +
                    muenChild.id +
                    '&name=' +
                    muenChild.name +
                    '&seo_title=' +
                    muenChild.seo_title +
                    '&seo_keywords=' +
                    muenChild.seo_keywords +
                    '&seo_description=' +
                    muenChild.seo_description
                  "
                >
                  {{ muenChild?.name }}
                </el-menu-item>
              </template>
              <el-menu-item
                :index="
                  muenChildList.route +
                  '?id=' +
                  muenChildList.id +
                  '&name=' +
                  muenChildList.name
                "
                v-for="muenChildList in muenChild.childlist"
                :key="muenChildList.id"
                v-show="muenChildList.route"
              >
                <span>{{ muenChildList?.name }}</span>
              </el-menu-item>
            </el-sub-menu>
          </el-sub-menu>
        </el-menu>
        <div class="right">
          <img
            class="el-image"
            v-for="(item, index) in iconList"
            :key="index"
            :src="item.blue_image"
            alt=""
            srcset=""
            style="width: 1.336vw; height: 1.336vw"
            @click="OpenUrl(item.url)"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="mobile-home" id="mobile-header">
    <div class="logo">
      <router-link to="/">
        <img :src="setList?.website_logo" alt="" />
      </router-link>
    </div>
    <div class="search">
      <el-input
        v-model="searchValue"
        class="w-50 m-2"
        size="large"
        placeholder="Search..."
        @change="changeSearch"
      >
      </el-input>
      <el-icon class="el-input__icon" @click="changeSearch"><Search /></el-icon>
      <span @click="openCn">CN</span>
      <el-icon :size="30" @click="opMenu" v-show="!isMenuShow"
        ><Operation
      /></el-icon>
      <el-icon :size="30" @click="opMenu" v-show="isMenuShow"
        ><CloseBold
      /></el-icon>
    </div>
    <el-menu
      :default-active="activeIndex"
      class="el-menu-demo"
      :ellipsis="false"
      @select="handleSelect"
      :unique-opened="true"
      router
      popper-offset="0"
      v-show="isMenuShow"
    >
      <div class="flex-grow" />
      <el-sub-menu
        :index="meun.id"
        v-for="meun in MenuList"
        :key="meun.id"
        @click="open5G(meun.url)"
      >
        <template #title>
          <el-menu-item
            @click="gbMenu"
            :index="
              meun.route +
              '?seo_title=' +
              meun.seo_title +
              '&seo_keywords=' +
              meun.seo_keywords +
              '&seo_description=' +
              meun.seo_description
            "
          >
            {{ meun?.name }}
          </el-menu-item>
        </template>
        <el-sub-menu
          v-for="muenChild in meun.childlist"
          :key="muenChild.id"
          :index="meun.id + '-' + muenChild.id"
        >
          <template #title>
            <el-menu-item
              @click="gbMenu"
              :index="
                muenChild.route +
                '?id=' +
                muenChild.id +
                '&name=' +
                muenChild.name +
                '&seo_title=' +
                muenChild.seo_title +
                '&seo_keywords=' +
                muenChild.seo_keywords +
                '&seo_description=' +
                muenChild.seo_description
              "
            >
              {{ muenChild?.name }}
            </el-menu-item>
          </template>
          <el-menu-item
            @click="gbMenu"
            :index="
              muenChildList.route +
              '?id=' +
              muenChildList.id +
              '&name=' +
              muenChildList.name
            "
            v-for="muenChildList in muenChild.childlist"
            :key="muenChildList.id"
            v-show="muenChildList.route"
          >
            <span>{{ muenChildList?.name }}</span>
          </el-menu-item>
        </el-sub-menu>
      </el-sub-menu>
    </el-menu>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import {
  Search,
  TopRight,
  location,
  Operation,
  CloseBold,
} from "@element-plus/icons-vue";
import api from "@/utils/api";
import { useStore } from "vuex";
const store = useStore();
const searchValue = ref();
import { useRouter } from "vue-router";
const router = useRouter();
const changeSearch = async () => {
  const params = { keywords: searchValue.value, page: 1 };
  await store.dispatch("getSearchList", params);
  router.push({
    path: `/search`,
    query: { keywords: searchValue.value },
  });
};
const open5G = (url: any) => {
  if (url) {
    window.open(url);
  } else {
    return;
  }
};
let MenuList = ref([]);
const getMenu = async () => {
  try {
    const res = await api.get("/common/navigation");
    MenuList.value = res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
let setList = ref();
const getSetConfig = async () => {
  try {
    const res = await api.get("/common/config");
    setList.value = res.data;
    store.commit("setData", res.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
let iconList = ref();
const getSetIcon = async () => {
  try {
    const res = await api.get("/common/icon");
    iconList.value = res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//这是点击跳转外部应用链接
const OpenUrl = (url: any) => {
  if (url) {
    window.open(url);
  } else {
    return;
  }
};
let observer = ref();
let HeadTop = ref();
const handleIntersection = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
    } else {
      // 元素离开可视区域
      entry.target.id = "fixHeader";
      HeadTop.value = "HeadTop";
    }
  });
};
const handleIntersectionMobile = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
    } else {
      // 元素离开可视区域
      entry.target.id = "fixHeader";
      HeadTop.value = "HeadTop2";
    }
  });
};
const openCn = () => {
  window.location.href = "https://www.lanran.com.cn/";
};
let isMenuShow = ref(false);
const opMenu = () => {
  isMenuShow.value = !isMenuShow.value;
};
const gbMenu = () => {
  isMenuShow.value = false;
};
onMounted(() => {
  getMenu();
  getSetConfig();
  getSetIcon();
  const targetElement = document.querySelector("#header");
  if (targetElement) {
    observer.value = new IntersectionObserver(handleIntersection);
    observer.value.observe(targetElement);
  }
  const targetElementMobile = document.querySelector("#mobile-header");
  if (targetElementMobile) {
    observer.value = new IntersectionObserver(handleIntersectionMobile);
    observer.value.observe(targetElementMobile);
  }
});
</script>

<style scoped lang="scss">
.page-home {
  width: 100%;
  height: 7vw;
  background-color: #fff;

  .header {
    width: 80.129vw;
    padding: 0.534vw 0;
    box-sizing: border-box;
    margin: 0 auto;
    z-index: 999;

    .top {
      display: flex;
      width: 100%;
      height: 2.671vw;
      justify-content: space-between;
      flex-wrap: wrap;
      align-content: center;
      .logo {
        width: 12%;
        height: 100%;
        img {
          width: 100%;
          height: 2.671vw;
        }
      }
      .search {
        display: flex;
        width: 15%;
        height: 100%;
        justify-content: flex-end;
        align-items: center;
        .el-input {
          border-radius: 20px;
          .el-input__icon {
            cursor: pointer;
          }
        }
        span {
          cursor: pointer;
          line-height: 30px;
          margin-left: 20px;
          font-size:1vw;
          &:hover {
            color: #669eff;
          }
        }
      }
    }
    .bottom {
      display: flex;
      width: 100%;
      height: 2.778vw;
      justify-content: space-between;
      margin-top: 10px;
      .el-menu {
        width: 90%;
        height: 100%;
        display: flex;
        border-bottom: none;
        align-content: center;
        flex-wrap: wrap;
        .el-sub-menu {
          // height: 100%;
          margin-right: 3.5vw;
          ::v-deep .el-sub-menu__title {
            padding-left: 0;
            padding-right: 0;
            // margin-right: 100px;
            // border-bottom: 2px solid white;
            box-sizing: border-box;
            .is-active {
              color: black;
            }
            i {
              display: none;
            }
          }
          &:nth-child(4) {
            + .el-menu-item {
              margin-right: 3.5vw;
            }
          }
          .el-sub-menu__title {
            .el-menu-item {
              color: black;
              font-size: 1.1vw;
            }
            .is-active {
              border-bottom-color: transparent !important ;
            }
          }
        }
        .is-active {
          border-bottom-color: transparent !important ;
        }

        .el-menu-item {
          height: 100%;
          // margin-right: 3.5vw;
          padding: 0;
          // border-bottom: 2px solid white;
          box-sizing: border-box;
          position: relative;

          .line {
            position: absolute;
            top: -50%;
            right: -1.8vw;
            transform: translate(0%,50%);
            width:1px;
            height:1vw;
          }
          .el-image {
            ::v-deep .el-image__inner {
              vertical-align: baseline;
            }
          }
        }
      }
      .right {
        width: 10%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .el-image {
          cursor: pointer;
        }
      }
    }
  }
}

#fixHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
}
#HeadTop {
  width: 100%;
  height: 7vw;
  background-color: #fff;
}
#HeadTop2 {
  width: 100%;
  height: 3.28rem;
  background-color: #fff;
}
</style>
<style  lang="scss">
.el-popper {
  width: auto;
  margin-left: -80px;
  inset: false;
  .el-menu--horizontal {
    .el-menu {
      width: 100%;
      padding: 0;
      .el-menu-item {
        padding: 30px 0px;
      }
      .el-sub-menu {
        padding: 14px 70px;
        cursor: pointer;
        .el-sub-menu__title {
          .el-menu-item {
            font-size: 20px;
          }
        }
        &:hover {
          background-color: #ecf5ff;
          transition: background-color 0.5s ease; /* 添加这一行 */
          .el-sub-menu__title {
            background-color: #ecf5ff;
            transition: background-color 0.5s ease; /* 添加这一行 */
            .el-menu-item {
              background-color: #ecf5ff;
              color: #669eff;
              transition: background-color 0.5s ease; /* 添加这一行 */
            }
          }
        }
        .el-icon {
          display: none;
        }
        .el-popper {
          width: auto;
          margin: -14px 0 0 70px;
          inset: auto auto auto auto;
          .el-menu--horizontal {
            .el-menu {
              background-color: #eaf3ff;
              li {
                background-color: #eaf3ff;
                border-bottom: 2px solid #eaf3ff;
                box-sizing: border-box;
                padding: 30px 22px;
                display: flex;
                font-size: 20px;
                color: #787878;
                .el-image {
                  z-index: 999;
                }
                &:hover {
                  span {
                    border-bottom: 2px solid #669eff;
                  }
                }
                span {
                  border-bottom: 2px solid transparent;
                }
              }
            }
          }
        }
      }
    }
  }
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: transparent !important;
}

.el-menu--horizontal > .el-sub-menu.is-active .el-sub-menu__title {
  border-bottom-color: transparent !important ;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #669eff !important;
}
////////////////////媒体查询pc端///////////////////////
@media screen and (min-width: 601px) {
  #HeadTop2 {
    height: 0 !important;
  }
  .mobile-home {
    display: none;
  }
}
////////////////////媒体查询手机端/////////////////////
@media screen and (max-width: 600px) {
  #HeadTop {
    height: 0 !important;
  }
  .page-home {
    display: none;
  }
  .mobile-home {
    position: relative;
    display: block;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 3.28rem;
    .logo {
      width: 10rem;
      height: 100%;
      img {
        width: 100%;
        height: 50px;
      }
    }
    .search {
      display: flex;
      width: 46%;
      height: 100%;
      justify-content: space-between;

      align-items: center;
    }
    .el-menu-demo {
      width: 100% !important;
      position: absolute;
      top: 3rem;
      left: 0;
      z-index: 999;
    }
  }
}
</style>
<style>
@media screen and (max-width: 600px) {
  .horizontal-collapse-transition .el-sub-menu__title .el-sub-menu__icon-arrow {
    opacity: 1 !important;
  }
}
</style>
