<template>
  <div class="home">
    <el-carousel class="top_carousel">
      <el-carousel-item v-for="(item, index) in bannerImg" :key="index">
        <img style="width: 100%; height: 100%" :src="item.image" />
        <div class="text">
          <div v-for="(text, index) in item.multiplejson" :key="index">
            <span>{{ text.title }}</span>
            <strong> {{ text.bold_title }}</strong>
          </div>
        </div>
        <button class="custom-btn btn-15" @click="goToDiscoverMore()">
          <span>More Details</span>
          <img
            class="arrow1"
            src="@/assets/imgs/Layout/arrow1.png"
            alt=""
            srcset=""
          />
          <img
            class="arrow3"
            src="@/assets/imgs/Layout/arrow3.png"
            alt=""
            srcset=""
          />
        </button>
      </el-carousel-item>
    </el-carousel>
    <div class="OurProcess" id="MyourProcess">
      <div class="left">
        <div class="title">Lanran's Mission</div>
        <div class="content">
          <h1>
            {{ bannerVideo1?.title }}
          </h1>
          <p>
            {{ bannerVideo1?.description }}
          </p>
        </div>
      </div>
      <div class="right" style="padding-right: 0 !important">
        <div class="title">Lanran's Mission</div>
        <video
          class="myMp4"
          :src="bannerVideo1?.vieo_file"
          playsinline
          autoplay
          muted
          loop
        ></video>
      </div>
    </div>
    <div class="map">
      <el-image
        style="width: 100%; height: 100%"
        :src="require(`@/assets/imgs/index/mapbackimg.jpg`)"
      />
      <div class="map_cont" id="mapCont">
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 100,100 100,50 90,50"
                fill="transparent"
              ></path>
              <text x="4" y="54" fill="#0044a0">Uzbekistan</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 92.5px;
              bottom: 40px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 100,100 100,50 90,50"
                fill="transparent"
              ></path>
              <text x="36" y="54" fill="#0044a0">Russia</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 92.5px;
              bottom: 40px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 100,70 100,50 90,50"
                fill="transparent"
              ></path>
              <text x="36" y="54" fill="#0044a0">Poland</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 92.5px;
              bottom: 70px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 100,70 100,50 90,50"
                fill="transparent"
              ></path>
              <text x="26" y="54" fill="#0044a0">Morocco</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 92.5px;
              bottom: 70px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 110,70 110,50 100,50"
                fill="transparent"
              ></path>
              <text x="0" y="54" fill="#0044a0">United States</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 102.5px;
              bottom: 70px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item" :id="lineGrowUpRight">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 110,70 110,50 100,50"
                fill="transparent"
              ></path>
              <text x="40" y="54" fill="#0044a0">Canada</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 102.5px;
              bottom: 70px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item_right" :id="lineGrowUpRed">
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="red"
                stroke-width="1px"
                d="M 10,200 10,50 20,50"
                fill="transparent"
              ></path>
              <text class="text" x="26" y="54" fill="red">
                Hangzhou (Headquarters)
              </text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 22px;
              left: 2px;
              bottom: -20px;
            "
            :src="require(`@/assets/imgs/index/SliceRed.png`)"
          />
        </div>
        <div class="map_cont_item_right" :id="lineGrowUpRight">
          <!-- <el-image :src="require(`@/assets/imgs/index/slice_right2.png`)" />
          <span>Malaysia</span> -->
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 10,100 10,50 20,50"
                fill="transparent"
              ></path>
              <text x="26" y="54" fill="#0044a0">South Korea</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 2.5px;
              bottom: 40px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item_right" :id="lineGrowUpRight">
          <!-- <el-image :src="require(`@/assets/imgs/index/slice_right.png`)" />
          <span>Korea</span> -->
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 10,80 10,50 20,50"
                fill="transparent"
              ></path>
              <text x="26" y="54" fill="#0044a0">Indonesia</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 2.5px;
              bottom: 60px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
        <div class="map_cont_item_right" :id="lineGrowUpRight">
          <!-- <el-image :src="require(`@/assets/imgs/index/slice_right.png`)" />
          <span>Korea</span> -->
          <svg style="position: reactive">
            <g>
              <path
                class="path"
                pathLength="1"
                stroke-dashoffset="1"
                stroke-dasharray="1,1"
                stroke="#0044a0"
                stroke-width="1px"
                d="M 10,80 10,50 20,50"
                fill="transparent"
              ></path>
              <text x="26" y="54" fill="#0044a0">Malaysia</text>
            </g>
          </svg>
          <el-image
            style="
              position: absolute;
              width: 15px;
              height: 15px;
              left: 2.5px;
              bottom: 60px;
            "
            :src="require(`@/assets/imgs/index/SliceBlue.png`)"
          />
        </div>
      </div>
    </div>
    <div class="cardbody" id="cardbody">
      <div class="card" v-for="(item, index) in cardList" :key="index">
        <div class="card_content">
          <img
            class="card_img"
            style="width: 22%; height: 25%"
            :src="item.image"
          />
          <div class="card_text">
            <span>{{ item.title }}</span>
            <span class="num" :ref="'numberContainer' + index">
              <AnimatedNumber
                v-if="card_show"
                class="myNum"
                :from="0"
                :to="item.number"
                :duration="2400"
              ></AnimatedNumber>
              <span class="num_right">{{ item.unit }}</span>
            </span>
          </div>
          <div class="bottom_text" :id="item.id">
            <p v-html="item.description"></p>
            <!-- <p>{{ item.text2 }}</p> -->
          </div>
        </div>
      </div>
    </div>
    <div class="Manufacturer">
      <div
        class="ManufacturerBanner"
        id="ManufacturerBanner"
        :style="{ backgroundImage: 'url(' + configData?.product_image + ')' }"
      >
        <p>
          {{ configData?.product_title }}
        </p>
        <div class="ManufacturerDiv">
          <button class="custom-btn btn-15" @click="goToProduct">
            <span> Product Center </span>
            <img
              class="arrow1"
              src="@/assets/imgs/Layout/arrow1.png"
              alt=""
              srcset=""
            />
            <img
              class="arrow3"
              src="@/assets/imgs/Layout/arrow3.png"
              alt=""
              srcset=""
            />
          </button>
        </div>
      </div>
    </div>
    <div class="text-center" style="padding: 100px 300px">
      <h1>{{ configData?.application_title }}</h1>
      <p v-html="configData?.application_description"></p>
      <button class="custom-btn btn-15" @click="productTodetails">
        <span>Our Solution</span>
        <img
          class="arrow3"
          src="@/assets/imgs/Layout/arrow3.png"
          alt=""
          srcset=""
        />
        <img
          class="arrow1"
          src="@/assets/imgs/Layout/arrow1.png"
          alt=""
          srcset=""
        />
      </button>
    </div>
    <div class="card-group" id="card-group">
      <div
        :class="'card-group-item' + index"
        v-for="(list, index) in solutionList"
        :key="list?.id"
        ref="card"
        @mouseover="addHoverEffect(list?.id, index)"
        @mouseout="removeHoverEffect(list?.id, index)"
      >
        <div class="card-body card-group-item">
          <div>
            <div class="h1">
              <h4>{{ list.name }}</h4>
            </div>
            <div class="card-text" style="font-size: 24px; padding-top: 70px">
              <span>
                {{ list.description }}
              </span>
            </div>
          </div>
          <div class="card-buttonList">
            <p class="h5">Navigation</p>
            <div class="card-btn-items">
              <button
                style="border: 1px solid #000000"
                class="custom-btn btn-15"
                v-for="(butList, index) in list.childlist"
                :key="index"
                @click="toDetails(butList.id)"
              >
                <span>
                  {{ butList.name }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="Customers"
      id="Customers"
      style="margin-top: 120px"
      v-if="isShow"
    >
      <video
        class="myMp4"
        :src="bannerVideo2?.vieo_file"
        autoplay
        muted
        loop
      ></video>
      <h1>{{ bannerVideo2?.title }}</h1>
    </div>
    <div class="cardSwiper" v-if="newRecommend?.length > 0">
      <div class="title">News</div>
      <div class="imglist">
        <el-carousel :interval="2000" type="card" :autoplay="false">
          <el-carousel-item
            v-for="(item, index) in newRecommend"
            :key="index"
            @click="newTodetails(item.id)"
          >
            <img
              class="el-image"
              style="width: 100%; height: 100%"
              :src="item.image"
            />
            <div class="text">
              <span>{{ item.createtime }}</span>
              <p>{{ item.title }}</p>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="ourCustomers" id="ourCustomers">
      <div class="title">Our Clients</div>
      <div class="content" style="margin-right: 0 !important">
        <div class="left">
          <p>
            <!-- Lanran thanks industry leaders for their <strong>Support</strong> -->
            {{ configData?.customer_title }}
          </p>
        </div>
        <div class="right" v-html="configData?.customer_description"></div>
      </div>
    </div>
    <div class="es-center-bottom">
      <div ref="listRef" class="es-bottom-list">
        <div class="es-bottom-item">
          <img
            v-for="(item, index) in bottomImgList"
            :key="index"
            :src="getImgUrl(item.image)"
            style="width: 180px; height: 180px"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="es-center-bottom">
      <div ref="listRef2" class="es-bottom-list">
        <div class="es-bottom-item" style="left: 150px">
          <img
            v-for="(item, index) in bottomImgList2"
            :key="index"
            :src="getImgUrl(item.image)"
            style="width: 180px; height: 180px"
            alt=""
          />
        </div>
      </div>
    </div>

    <div
      class="footerBanner"
      id="footerBanner"
      :style="{ backgroundImage: 'url(' + configData?.solution_image + ')' }"
    >
      <h1 :id="footerBannerText">
        <!-- Let's Talk About It! We Offer The Perfect <br />Solution For
        <span>Your Industry.</span> -->
        {{ configData?.solution_title }}
      </h1>
      <div class="mt-5" :id="footerBannerText">
        <button class="custom-btn btn-15" @click="productToContactUs">
          <span>Contact Us</span>
          <img
            class="arrow1"
            src="@/assets/imgs/Layout/arrow1.png"
            alt=""
            srcset=""
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
  ref,
  reactive,
  onMounted,
  onUnmounted,
  watch,
  shallowRef,
  computed,
  watchEffect,
} from "vue";
import CountUp from "vue-countup";
import { useRouter } from "vue-router";
import api from "@/utils/api";
import { getAllBanner } from "@/api/banner";
import anime from "animejs";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
//banner图
const bannerImg = ref([]);
//指标数据
const cardList = ref([]);

const ulsRef = ref();
const ulsRef2 = ref();

const timer = ref();
let i = ref(0);
const observer = ref();
const productTodetails = (id: any) => {
  router.push({
    path: `/application`,
  });
};

//获取banner图
const getBanner = async () => {
  try {
    const res = await getAllBanner("home");
    bannerImg.value = res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
const goToDiscoverMore = () => {
  router.push({
    path: `/application`,
  });
};
const goToProduct = () => {
  router.push({
    path: `/product`,
  });
};
//获取视频1
const bannerVideo1 = ref();
const getVideo1 = async () => {
  try {
    const res = await getAllBanner("home_video1");
    bannerVideo1.value = res.data[0];
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//获取视频2
const bannerVideo2 = ref();
let isShow = ref(true);
const getVideo2 = async () => {
  try {
    const res = await getAllBanner("home_video2");
    bannerVideo2.value = res.data[0];
    if (bannerVideo2.value) {
      const hasContent = computed(() => {
        return bannerVideo2.value.vieo_file.includes(
          "https://lanran.yousin.cn/backend/public/"
        );
      });
      isShow.value = hasContent.value;
    } else {
      isShow.value = false;
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//获取指标数据
const getIndicator = async () => {
  try {
    const res = await api.get(`/common/indicator`);
    cardList.value = res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
// //获取首页配置产品,方案等等小地方的图文
const configData = ref();
//获取我的客户
let bottomImgList = ref([]);
let bottomImgList2 = ref([]);
const getCustomer = async () => {
  try {
    const res = await api.get(`/common/customer`);
    bottomImgList.value = res.data;
    let copy = JSON.parse(JSON.stringify(bottomImgList.value));
    bottomImgList2.value = copy.reverse();
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//获取推荐方案
let solutionList = ref([]);
const getCommonSolution = async () => {
  try {
    const res = await api.get(`/common/solution`);
    solutionList.value = res.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//推荐新闻
let newRecommend = ref();
const getRecommend = async () => {
  try {
    const response = await api.get("/news/recommend");
    newRecommend.value = response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};
//跳转新闻详情
const newTodetails = (id: any) => {
  router.push({
    path: `/aboutlanran/NewDetails/NewDetailsDeep`,
    query: { id },
  });
};
const getImgUrl = (src: string): string => {
  return new URL(`${src}`, import.meta.url).href;
};
//跳转膜应用3级
const toDetails = (id: any) => {
  router.push({
    path: `/application/applicationNewEnergy/applicationNewEnergyDetails`,
    query: {
      id: id,
    },
  });
};
//跳转联系我们
const productToContactUs = () => {
  router.push({
    path: `/contact_us`,
  });
};

///动画相关代码
const listRef = ref();
let spacing = 1000; //模块之间的间距
var animation = shallowRef<ReturnType<typeof anime>>();
const init = () => {
  // 动画中所有模块实例对象集合
  const children = listRef.value?.children || [];
  //如果没有实例作拦截
  if (!children.length) return;

  // 获取列表集合中第一个元素宽，通过宽和元素之间的间距计算出移动距离firstDiff
  const firstEl = children[0] as HTMLElement;
  const firstDiff = firstEl.offsetWidth + spacing;

  // 默认将list元素向左移动一个item的距离
  listRef.value!.style.transform = `translateX(-${firstDiff}px)`;

  const transList: any = [];
  let total = 0; //声明总宽
  // 设置初始位置
  anime.set(children, {
    ["translateX"]: (el: HTMLElement, i: number) => {
      //计算得出总宽
      const distance = el.offsetWidth + spacing;
      total += distance;
      //设置初始运动点为0
      const diff = (i + 1) * distance;
      //收集所有小模块运动x轴点值
      transList[i] = { ["x"]: diff };
      return diff;
    },
  });
  // 设置list容器的宽或高
  listRef.value!.style["width"] = total + "px";

  // 添加动画
  animation.value = anime({
    targets: transList,
    duration: 18000, //一个动画时间
    easing: "linear",
    direction: "reverse",
    ["x"]: `+=${total}`,
    loop: true, //是否循环
    update: () => {
      anime.set(children, {
        ["translateX"]: (_el: any, i: string | number) => {
          return transList[i]["x"] % total;
        },
      });
    },
  });
};
///动画相关代码2
const listRef2 = ref();
let spacing2 = 1000; //模块之间的间距
var animation = shallowRef<ReturnType<typeof anime>>();
const init2 = () => {
  // 动画中所有模块实例对象集合
  const children = listRef2.value?.children || [];
  //如果没有实例作拦截
  if (!children.length) return;

  // 获取列表集合中第一个元素宽，通过宽和元素之间的间距计算出移动距离firstDiff
  const firstEl = children[0] as HTMLElement;
  const firstDiff = firstEl.offsetWidth + spacing2;

  // 默认将list元素向左移动一个item的距离
  listRef2.value!.style.transform = `translateX(-${firstDiff}px)`;

  const transList: any = [];
  let total = 0; //声明总宽
  // 设置初始位置
  anime.set(children, {
    ["translateX"]: (el: HTMLElement, i: number) => {
      //计算得出总宽
      const distance = el.offsetWidth + spacing2;
      total += distance;
      //设置初始运动点为0
      const diff = (i + 1) * distance;
      //收集所有小模块运动x轴点值
      transList[i] = { ["x"]: diff };
      return diff;
    },
  });
  // 设置list容器的宽或高
  listRef2.value!.style["width"] = total + "px";

  // 添加动画
  animation.value = anime({
    targets: transList,
    duration: 18000, //一个动画时间
    easing: "linear",
    direction: "reverse",
    ["x"]: `+=${total}`,
    loop: true, //是否循环
    update: () => {
      anime.set(children, {
        ["translateX"]: (_el: any, i: string | number) => {
          return transList[i]["x"] % total;
        },
      });
    },
  });
};

onMounted(async () => {
  await getBanner();
  await getVideo1();
  await getVideo2();
  await getIndicator();
  await getCustomer();
  await init();
  await init2();
  await getCommonSolution();
  await getRecommend();
  configData.value = store.state.configData;
  //更改页面元标签的keywords等等
  // 更新logo
  const logoElement = document.getElementById("page-logo");
  if (logoElement) {
    logoElement.setAttribute("href", configData.value.icon_image);
  }
  // 更新title标签
  const metaTitle = document.getElementById("page-title");
  if (metaTitle) {
    metaTitle.textContent = configData.value.website_title;
  }
  // 更新keyword
  const metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", configData.value.website_keyword);
  }
  // 更新内容标签
  const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute(
      "content",
      configData.value.website_description
    );
  }
  const MyourProcess = document.querySelector("#MyourProcess");
  // our process
  if (MyourProcess) {
    observer.value = new IntersectionObserver(handleInterProcess);
    observer.value.observe(MyourProcess);
  }

  // ---------
  // cardbody
  const cardbody = document.querySelector("#cardbody");
  if (cardbody) {
    observer.value = new IntersectionObserver(handleInterCard);
    observer.value.observe(cardbody);
  }
  // ---------
  // ManufacturerBanner
  const ManufacturerBanner = document.querySelector("#ManufacturerBanner");
  if (ManufacturerBanner) {
    observer.value = new IntersectionObserver(handleInterManufacturerBanner);
    observer.value.observe(ManufacturerBanner);
  }
  // ---------
  // textCenter
  const textCenter = document.querySelector(".text-center");
  if (textCenter) {
    observer.value = new IntersectionObserver(handleIntertextCenter);
    observer.value.observe(textCenter);
  }
  // ---------
  // cardGroup
  const cardGroup = document.querySelector("#card-group");
  if (cardGroup) {
    observer.value = new IntersectionObserver(handleIntertextCardGroup);
    observer.value.observe(cardGroup);
  }
  // ---------
  // ourCustomers
  const ourCustomers = document.querySelector("#ourCustomers");
  if (ourCustomers) {
    observer.value = new IntersectionObserver(handleIntertextourCustomers);
    observer.value.observe(ourCustomers);
  }

  // ---------
  // mapCont
  const mapCont = document.querySelector("#mapCont");
  if (mapCont) {
    observer.value = new IntersectionObserver(handleIntertexmapCont);
    observer.value.observe(mapCont);
  }
  // ---------
  // footerBanner
  const footerBanner = document.querySelector("#footerBanner");
  if (footerBanner) {
    observer.value = new IntersectionObserver(handleIntertexfooterBanner);
    observer.value.observe(footerBanner);
  }
  // ---------
});
onUnmounted(() => {
  clearInterval(timer.value);
});
// our process
const handleInterProcess = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      const title = entry.target.querySelector(".title");
      const title2 = entry.target.querySelector(".right .title");
      if (title2) {
        title2.id = "myMoveRightFast";
      }
      const content = entry.target.querySelector(".content");
      title.id = "myMoveRightFast";
      content.id = "myMoveRightSlow";
    } else {
    }
  });
};
// cardbody
let card_show = ref(false);
let isVisible = reactive([]);
const handleInterCard = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      card_show.value = true;
    } else {
    }
  });
};
// ManufacturerBanner
const handleInterManufacturerBanner = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      const p = entry.target.querySelector("p");
      p.id = "myMoveTop";
    } else {
    }
  });
};
// textCenter
const handleIntertextCenter = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      const p = entry.target.querySelector("p");
      const h1 = entry.target.querySelector("h1");

      p.id = "myMoveTop";
      h1.id = "myMoveTop";
    } else {
    }
  });
};
// CardGroup
const handleIntertextCardGroup = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      const card_group_item0 = entry.target.querySelector(".card-group-item0");
      const card_group_item1 = entry.target.querySelector(".card-group-item1");
      const card_group_item2 = entry.target.querySelector(".card-group-item2");
      card_group_item0.id = "myMoveCard-group-item";
      card_group_item1.id = "myMoveCard-group-item";
      card_group_item2.id = "myMoveCard-group-item";
    } else {
    }
  });
};
// ourCustomers
const handleIntertextourCustomers = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      const title = entry.target.querySelector(".title");
      title.id = "myMoveRightFast";
      const left = entry.target.querySelector(".content .left");
      const right = entry.target.querySelector(".content .right");
      left.id = "myMoveRightSlow";
      right.id = "myMoveLeftSlow";
    } else {
    }
  });
};
const lineGrowUpRed = ref("");
const lineGrowUpRight = ref("");

// mapCont
const handleIntertexmapCont = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      lineGrowUpRed.value = "lineGrowUpRed";
      lineGrowUpRight.value = "lineGrowUpRight";
    } else {
    }
  });
};
//footerBanner
const footerBannerText = ref("");
const handleIntertexfooterBanner = (entries: any) => {
  entries.forEach((entry: any) => {
    if (entry.isIntersecting) {
      // 元素进入可视区域
      footerBannerText.value = "footerBannerText";
    } else {
      // 元素离开可视区域
    }
  });
};
let card = ref();
const addHoverEffect = (id: any, index: any) => {
  card.value[
    index
  ].style.backgroundImage = `url(${solutionList.value[index].image})`;
  card.value[index].style.transition = "all 0.5s";
};
const removeHoverEffect = (id: any, index: any) => {
  card.value[index].style.backgroundImage = "";
};
//媒体查询
let isMobile = ref(false);
// 使用 watchEffect 监听窗口大小变化
watchEffect(() => {
  // 媒体查询条件，可以根据实际情况修改
  const mediaQuery = window.matchMedia("(max-width: 600px)");
  isMobile.value = mediaQuery.matches;
  if (isMobile.value && card.value) {
    let i;
    for (i = 0; i < 3; i++) {
      card.value[
        i
      ].style.backgroundImage = `url(${solutionList.value[i].image})`;
    }
  }
});
</script>


<style scoped lang="scss">
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
p,
span,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "open Sans";
}
.home {
  width: 100%;
  .top_carousel {
    width: 90%;
    margin: 0 auto;
    ::v-deep .el-carousel__container {
      position: relative;
      height: 40vw;
      .el-carousel__item {
        .text {
          display: flex;
          position: absolute;
          width: 100%;
          height: 180px;
          top: 26%;
          // left: 30%;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          animation: moveTop 1.5s forwards ease;
          opacity: 0;
          div {
            display: flex;
            // justify-content: space-evenly;
            &:nth-child(2) {
              margin-left: 26px;
            }
            span {
              font-size: 70px;
              color: white;
              font-family: "Inter-Extra Light";
              margin-right: 20px;
              font-weight: 200;
            }
            strong {
              font-size: 70px;
              color: white;
              font-family: "Inter-Bold";
            }
          }
        }
        .custom-btn {
          position: absolute;
          top: -36%;
          left: 0;
          color: #fff;
          border-radius: 20rem;
          padding: 10px 25px;
          box-sizing: border-box;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          background: transparent;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          display: inline-block;
          outline: none;
          span {
            font-size: 22px;
          }
          .arrow1 {
            width: 21px;
            height: 21px;
            margin: 0 0 -3px 5px;
          }
          .arrow3 {
            display: none;
            width: 16px;
            height: 16px;
            margin: 0 0 -1px 9px;
          }
        }
        .btn-15 {
          background: #ffffff;
          color: #0044a0;
          border: none;
          z-index: 1;
          // border-radius: 20rem;
          overflow: hidden;
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #00a2e9;
            // border-radius: 20rem;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #fff;
            background: transparent;

            .arrow1 {
              display: none;
            }
            .arrow3 {
              display: inline-block;
              transition: all 0.5s;
            }
            &:after {
              left: 0;
              width: 100%;
              border-radius: 20rem;
            }
          }
        }
      }
    }
  }
  .OurProcess {
    display: flex;
    margin-top: 45px;
    .left {
      width: 50%;
      .title {
        background-image: url("@/assets/imgs/index/titleBg.png");
        background-repeat: no-repeat;
        text-align: center;
        background-size: contain;
        width: 324px;
        height: 37px;
        line-height: 37px;
      }
      .content {
        width: 75%;
        margin: 0 auto;
        text-align: left;
        padding-top: 1vh;
        box-sizing: border-box;
        h1 {
          font-size: 36px;
          line-height: 50px;
        }
        p {
          font-size: 21px;
          line-height: 34px;
          font-family: "open Sans";
          color: #4e5461;
          text-align: justify;
        }
      }
    }
    .right {
      width: 50%;
      .title {
        display: none;
        background-image: url("@/assets/imgs/index/titleBg.png");
        background-repeat: no-repeat;
        text-align: center;
        background-size: contain;
      }
      .myMp4 {
        width: 100%;
      }
    }
  }
  .map {
    width: 1518px;
    margin: 0 auto;
    margin-bottom: 10vh;
    margin-top: -10vh;
    z-index: -1;
    position: relative;
    .map_cont {
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;
      .map_cont_item {
        position: absolute;
        top: 374px;
        left: 59%;
        display: flex;
        + .map_cont_item {
          top: 265px;
          left: 56.5%;

          + .map_cont_item {
            top: 354px;
            left: 48.2%;
            + .map_cont_item {
              top: 444px;
              left: 42%;
              + .map_cont_item {
                top: 420px;
                left: 20%;
                + .map_cont_item {
                  top: 320px;
                  left: 17.5%;
                }
              }
            }
          }
        }
      }
      .map_cont_item_right {
        position: absolute;
        top: 358px;
        right: 3.4%;
        display: flex;
        .path {
        }
        + .map_cont_item_right {
          top: 396px;
          right: 0.8%;
          + .map_cont_item_right {
            top: 550px;
            right: 4.2%;
            .el-image {
              width: 20px;
              height: 40px;
              margin: 4px 3px 0 0;
            }
            + .map_cont_item_right {
              top: 536px;
              right: 7.6%;
            }
          }
        }
      }
    }
  }
  .cardbody {
    width: 100%;
    // height: 700px;
    padding: 10px 80px 80px 80px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-around;
    .card {
      width: 30%;
      background-color: #eaf3ff;
      border-bottom: 3px solid #0044a0;
      padding: 3vh 0;
      .card_content {
        margin: 0 auto;
        width: 85%;
        .card_img {
          margin: 20px 0 30px 0;
        }
        .card_text {
          border-bottom: 1px solid #d5e7ff;

          span {
            font-size: 25px;
            color: #4e5461;
          }
          .num {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            font-size: 25px;
            color: #b5b8bc;
            margin: 10px 0 32px 0;
            width: 100%;
            .myNum {
              width: 55%;
              color: #0044a0;
              // margin: 0 0 0 40px;
              font-size: 42px;
              font-weight: bolder;
              text-align: right;
            }
            .num_right {
              width: 45%;
              text-align: left;
              padding-left: 20px;
              box-sizing: border-box;
              color: #4e5461;
            }
            // b {
            //   color: #0044a0;
            //   margin-right: 10px;
            //   font-size: 42px;
            //   font-weight: bolder;
            // }
          }
        }
        .bottom_text {
          margin: 34px 0;
          p {
            color: black;
            font-size: 25px;
            line-height: 10px;
            font-family: "open Sans";
          }
        }
      }
    }
  }
  .Manufacturer {
    .ManufacturerBanner {
      position: relative;
      width: 100%;
      height: 600px;
      // background: url("@/assets/imgs/index/Rectangle46.png");
      color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;
      p {
        font-size: 45px;
        margin: 0;
        width: 65vw;
      }
      .ManufacturerDiv {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        .custom-btn {
          position: absolute;
          top: 0;
          left: 0;
          color: #fff;
          border-radius: 5px;
          padding: 10px 25px;
          box-sizing: border-box;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          background: transparent;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          display: inline-block;
          outline: none;
          span {
            font-size: 22px;
          }
          .arrow1 {
            width: 16px;
            height: 16px;
            margin: 0 0 -1px 3px;
          }
          .arrow3 {
            display: none;
            width: 12px;
            height: 12px;
            margin: 0 0 0 5px;
          }
        }
        .btn-15 {
          background: #cbe2ff;
          color: #0044a0;
          border: none;
          z-index: 1;
          border-radius: 20rem;
          overflow: hidden;
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #00a2e9;
            border-radius: 20rem;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #fff;
            background: transparent;

            .arrow1 {
              display: none;
            }
            .arrow3 {
              display: inline-block;
              transition: all 0.5s;
            }
            &:after {
              left: 0;
              width: 100%;
              border-radius: 20rem;
            }
          }
          &:active {
            top: 2px;
          }
        }
      }
    }
  }
  .text-center {
    h1 {
      font-size: 36px;
      margin-bottom: 50px;
    }
    p {
      line-height: 24px;
      margin-bottom: 5vh;
      font-size: 20px;
    }
    .custom-btn {
      color: #0044a0;
      border-radius: 5px;
      padding: 10px 25px;
      box-sizing: border-box;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      background: transparent;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      outline: none;
      span {
        font-size: 22px;
      }
      .arrow3 {
        width: 12px;
        height: 12px;
        margin: 0 0 0 5px;
      }
      .arrow1 {
        display: none;
        width: 16px;
        height: 16px;
        margin: 0 0 -2px 3px;
      }
    }
    .btn-15 {
      background-color: #00a2e9;
      color: #fff;
      border: none;
      z-index: 1;
      border-radius: 20rem;
      overflow: hidden;
      border: 1px solid transparent;

      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background: #fff;
        border-radius: 20rem;
        transition: all 0.3s ease;
      }
      &:hover {
        color: #0044a0;
        background: transparent;
        border: 1px solid #0044a0;
        .arrow3 {
          display: none;
        }
        .arrow1 {
          display: inline-block;
          transition: all 0.5s;
        }
        &:after {
          left: 0;
          width: 100%;
          border-radius: 20rem;
        }
      }
      &:active {
        top: 2px;
      }
    }
  }
  .card-group {
    display: flex;
    .card-group-item0,
    .card-group-item1,
    .card-group-item2 {
      width: 33.3%;
      text-align: left;
      border: 1px solid #d2d2d2;

      .h5 {
        font-size: 20px;
        margin-bottom: 40px;
      }
      .card-btn-items {
        .custom-btn {
          margin: 0 10px 20px 0;
        }
      }
      .card-group-item {
        display: flex;
        flex-direction: column;
        height: 819px;
        padding: 40px;
        .card-buttonList {
          margin-top: 20%;
        }
        .h1 {
          font-size: 38px;
          font-weight: 500;
          margin-bottom: 20px;
        }
        .card-text {
          line-height: 36px;
        }
      }
    }
    .card-group-item0,
    .card-group-item1 {
      border-right: 0;
    }
    // .card-group-item1,
    // .card-group-item2,
    // .card-group-item3 {
    // }
    .custom-btn {
      position: absolute;
      top: -40%;
      left: 0;
      color: #fff;
      border-radius: 5px;
      padding: 10px 25px;
      box-sizing: border-box;
      font-family: "Lato", sans-serif;
      font-weight: 500;
      background: transparent;
      cursor: pointer;
      transition: all 0.3s ease;
      position: relative;
      display: inline-block;
      outline: none;
      span {
        font-size: 20px;
      }
      .arrow1 {
        margin: 0 0 -3px 5px;
      }
    }
    .btn-15 {
      background: #ffffff;
      color: #000000;
      border: none;
      z-index: 1;
      border-radius: 20rem;
      overflow: hidden;
      &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 100%;
        top: 0;
        right: 0;
        z-index: -1;
        background-color: #00a2e9;
        border-radius: 20rem;
        transition: all 0.3s ease;
      }
      &:hover {
        color: #fff;
        background: transparent;

        .arrow1 {
          filter: invert(1);
          transition: all 0.5s;
        }
        &:after {
          left: 0;
          width: 100%;
          border-radius: 20rem;
        }
      }
      &:active {
        top: 2px;
      }
    }

    .card-group-item0:hover {
      // background: url("@/assets/imgs/index/Rectangle60.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      // transition: all 0.5s;
    }

    .card-group-item0:hover .btn-15 {
      background-color: transparent;
      border-color: #ffffff !important;
      color: #ffffff !important;
      .arrow1 {
        filter: invert(1);
      }
    }
    .card-group-item1:hover {
      // background: url("@/assets/imgs/index/facture1.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      transition: all 0.5s;
      .arrow1 {
        filter: invert(1);
      }
    }
    .card-group-item1:hover .btn-15 {
      background-color: transparent;
      border-color: #ffffff !important;
      color: #ffffff !important;
    }
    .card-group-item2:hover {
      // background: url("@/assets/imgs/index/facture2.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      color: white;
      transition: all 0.5s;
      .arrow1 {
        filter: invert(1);
      }
    }
    .card-group-item2:hover .btn-15 {
      background-color: transparent;
      border-color: #ffffff !important;
      color: #ffffff !important;
    }
  }
  .Customers {
    width: 100%;
    position: relative;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    .myMp4 {
      width: 100%;
    }
    h1 {
      font-size: 50px;
      position: absolute;
      width: 100%;
      top: 49vh;
      z-index: 999;
    }
  }
  .cardSwiper {
    width: 100%;
    // height: 500px;
    margin: 10vh 0;

    .title {
      background-image: url("@/assets/imgs/index/titleBg.png");
      background-repeat: no-repeat;
      text-align: center;
      width: 324px;
      background-size: contain;
      height: 37px;
      line-height: 37px;
      position: relative;
      animation: moveRight 1.2s forwards ease;
      opacity: 0;
    }
    .imglist {
      // padding: 0 80px;
      width: 70%;
      margin: 0 auto;
      box-sizing: border-box;
      margin-top: 10vh;
      .el-carousel {
        ::v-deep .el-carousel__container {
          height: 22vw;
          position: relative;

          .el-carousel__item {
            width: 50%;
            border-radius: 15px;
            &:hover {
              .text {
                left: 8%;

                span {
                  font-size: 18px;
                }
                p {
                  font-size: 30px;
                }
              }
            }
            .text {
              position: absolute;
              top: 46%;
              left: 10%;
              width: 80%;
              height: 110px;
              transition: left 0.5s ease;
              color: white;
              // animation: moveTop 1.5s forwards ease;
              // opacity: 0;
              span {
                transition: font-size 0.5s ease;
              }
              p {
                font-size: 28px;
                font-weight: 300;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                transition: font-size 0.5s ease;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
  .ourCustomers {
    margin-bottom: 10vh;
    .title {
      background-image: url("@/assets/imgs/index/titleBg.png");
      background-repeat: no-repeat;
      text-align: center;
      width: 324px;
      background-size: contain;
      height: 37px;
      line-height: 37px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      padding: 0 120px;
      box-sizing: border-box;
      height: 200px;
      .left,
      .right {
        text-align: left;
      }
      .left {
        width: 30%;
        font-size: 35px;
        strong {
          font-weight: 700;
        }
      }
      .right {
        width: 44%;
        font-size: 15px;
        color: #b9b9b9;
      }
    }
  }
  .es-center-bottom {
    position: relative;
    width: 100%;
    height: 260px;
    overflow: hidden;

    .es-bottom-list {
      width: 100%;
      height: auto;
      .es-bottom-item {
        width: 100%;

        position: absolute;
        top: 0;
        left: 0;
        // width: 70px;
        // height: 80px;
        display: flex;
        // flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
          padding: 20px;
          border: 1px solid grey;
          border-radius: 15px;
          width: 40px;
          margin: 0 50px;
        }
      }
    }
  }
  .footerBanner {
    margin-top: 20vh;
    height: 460px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    text-align: center;
    color: #ffffff;
    padding: 100px;
    box-sizing: border-box;
    h1,
    .mt-5,
    p {
      position: relative;
      opacity: 0;
    }
    h1 {
      font-weight: 100;
      // font-family: 'Inter-Extra Light';
      span {
        font-weight: bolder;
        font-size: 36px;
      }
    }
    .mt-5 {
      margin-top: 3rem !important;
      .custom-btn {
        color: #fff;
        border-radius: 5px;
        padding: 10px 25px;
        box-sizing: border-box;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        outline: none;
        span {
          font-size: 20px;
        }
        .arrow1 {
          width: 21px;
          height: 21px;
          margin: 0 0 -3px 5px;
        }
      }
      .btn-15 {
        background: #ffffff;
        color: #0044a0;
        border: none;
        z-index: 1;
        border-radius: 20rem;
        overflow: hidden;
        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 100%;
          top: 0;
          right: 0;
          z-index: -1;
          background-color: #00a2e9;
          border-radius: 20rem;
          transition: all 0.3s ease;
        }
        &:hover {
          color: #fff;
          background: transparent;

          .arrow1 {
            filter: invert(1);
            transition: all 0.5s;
          }
          &:after {
            left: 0;
            width: 100%;
            border-radius: 20rem;
          }
        }
        &:active {
          top: 2px;
        }
      }
    }
  }
}
#myMoveLeftSlow {
  position: relative;
  animation: moveLeft 1.5s forwards ease;
  opacity: 0;
}
#myMoveRightSlow {
  position: relative;
  animation: moveRight 1.5s forwards ease;
  opacity: 0;
}
#myMoveRightFast {
  position: relative;
  animation: moveRight 1.2s forwards ease;
  opacity: 0;
}

#myMoveTop {
  // position: relative;
  animation: moveTop 2s forwards ease;
  opacity: 0;
}
#myMoveCard-group-item {
  .card-body {
    div {
      .h1 {
        position: relative;
        animation: moveRight 1s forwards ease;
        opacity: 0;
      }
      .card-text {
        position: relative;
        animation: moveRight 1.5s forwards ease;
        opacity: 0;
      }
      .h5 {
        position: relative;
        animation: moveRight 2s forwards ease;
        opacity: 0;
      }
      .card-btn-items {
        position: relative;
        animation: moveRight 2s forwards ease;
        opacity: 0;
      }
    }
  }
}

@keyframes moveRight {
  0% {
    transform: translateX(-10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes moveLeft {
  0% {
    transform: translateX(10%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes moveTop {
  0% {
    transform: translateY(60%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
#lineGrowUpRight {
  .path {
    animation: move 1.8s ease-out forwards;
    animation-delay: 1.4s;
  }
  .el-image {
    animation: moveText 2s ease-out forwards;
    opacity: 0;
    animation-delay: 0.4s;
  }
}
#lineGrowUpRed {
  .path {
    animation: move 1.5s ease-out forwards;
    animation-delay: 0.2s;
    .text {
      animation: moveText 2s forwards ease;
    }
  }
  .el-image {
    animation: moveText 2s ease-out forwards;
  }
}
@keyframes move {
  0% {
    stroke-dashoffset: 1;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes moveText {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#footerBannerText {
  animation: moveTop 1.5s forwards ease;
}

////////////////////媒体查询pc端///////////////////////
@media screen and (min-width: 601px) {
}
////////////////////媒体查询手机端/////////////////////
@media screen and (max-width: 600px) {
  .top_carousel {
    width: 100% !important;
  }
  .home {
    .el-carousel {
      ::v-deep .el-carousel__container {
        height: 50vw;
        .el-carousel__item {
          .text {
            display: flex;
            position: absolute;
            width: 100%;
            height: 4.8rem;
            top: 26%;
            // left: 30%;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            animation: moveTop 1.5s forwards ease;
            opacity: 0;
            div {
              display: flex;
              // justify-content: space-evenly;
              &:nth-child(2) {
                margin-left: 0.69rem;
              }
              span {
                font-size: 1.94rem;
                color: white;
                font-family: "Inter-Extra Light";
                margin-right: 0.53rem;
                font-weight: 200;
              }
              strong {
                font-size: 1.94rem;
                color: white;
                font-family: "Inter-Bold";
              }
            }
          }
          .custom-btn {
            position: absolute;
            top: -36%;
            left: 0;
            color: #fff;
            border-radius: 20rem;
            padding: 0.26rem 0.66rem;
            box-sizing: border-box;
            font-family: "Lato", sans-serif;
            font-weight: 500;
            background: transparent;
            cursor: pointer;
            transition: all 0.3s ease;
            position: relative;
            display: inline-block;
            outline: none;
            span {
              font-size: 0.58rem;
            }
            .arrow1 {
              width: 0.56rem;
              height: 0.56rem;
              margin: 0 0 -0.08rem 0.13rem;
            }
            .arrow3 {
              display: none;
              width: 0.42rem;
              height: 0.42rem;
              margin: 0 0 -0.026rem 0.24rem;
            }
          }
          .btn-15 {
            background: #ffffff;
            color: #0044a0;
            border: none;
            z-index: 1;
            // border-radius: 20rem;
            overflow: hidden;
            &::after {
              position: absolute;
              content: "";
              width: 0;
              height: 100%;
              top: 0;
              right: 0;
              z-index: -1;
              background-color: #0044a0;
              // border-radius: 20rem;
              transition: all 0.3s ease;
            }
            &:hover {
              color: #fff;
              background: transparent;

              .arrow1 {
                display: none;
              }
              .arrow3 {
                display: inline-block;
                transition: all 0.5s;
              }
              &:after {
                left: 0;
                width: 100%;
                border-radius: 20rem;
              }
            }
          }
        }
      }
    }
    .OurProcess {
      flex-direction: column-reverse;
      .left {
        width: 100%;
        .title {
          display: none;
        }
        .content {
          h1 {
            font-size: 0.96rem;
            line-height: 1.3rem;
          }
          p {
            font-size: 0.56rem;
            line-height: 0.9rem;
          }
        }
      }
      .right {
        .title {
          display: block;
          width: 8.64rem;
          line-height: 0.98rem;
          font-size: 0.5rem;
          margin-bottom: 1rem;
        }
        width: 100%;
      }
    }
    .map {
      width: 380px;
      margin-top: 0;
      height: 260px;
      .map_cont {
        .map_cont_item {
          transform: scale(0.3);
          top: 26%;
          left: 30%;
          display: flex;
          + .map_cont_item {
            top: 13%;
            left: 29%;
            + .map_cont_item {
              top: 14%;
              left: 16%;
              + .map_cont_item {
                top: 23%;
                left: 19%;
                + .map_cont_item {
                  top: 34%;
                  left: 13%;
                  + .map_cont_item {
                    top: 30%;
                    left: -10%;
                    + .map_cont_item {
                      top: 20%;
                      left: -12%;
                    }
                  }
                }
              }
            }
          }
        }
        .map_cont_item_right {
          position: absolute;
          transform: scale(0.3);
          top: 24%;
          right: -28%;
          display: flex;
          .path {
          }
          + .map_cont_item_right {
            top: 29%;
            right: -31%;
            + .map_cont_item_right {
              top: 47.5%;
              right: -27%;
              + .map_cont_item_right {
                top: 46%;
                right: -24%;
              }
            }
          }
        }
      }
    }
    .cardbody {
      width: 96%;
      padding: 0;
      margin: 0 auto;
      flex-direction: column;
      .card {
        width: 80%;
        padding: 0.3rem 0 !important;
        margin: 1rem auto;

        .card_content {
          .card_img {
            margin: 0.53rem 0 0.8rem 0 !important;
          }
          .card_text {
            span {
              font-size: 1rem !important;
            }
            .num {
              margin: 0.4rem 0 0.85rem 0 !important;
              .myNum,
              .num_right {
                font-size: 1.4rem !important;
              }
              .num_right {
                line-height: 1.5rem;
                padding-left: 0.53rem !important;
              }
            }
          }
          .bottom_text {
            margin: 0.9rem 0 !important;
            p {
              font-size: 0.6rem;
              line-height: 0.26rem;
            }
          }
        }
      }
    }
    .Manufacturer {
      .ManufacturerBanner {
        height: 16rem;
        p {
          font-size: 1.2rem;
        }
        .custom-btn {
          position: absolute;
          top: 100%;
          left: 0;
          color: #fff;
          border-radius: 5px;
          padding: 0.26rem 0.66rem;
          box-sizing: border-box;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          background: transparent;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          display: inline-block;
          outline: none;
          span {
            font-size: 0.58rem;
          }
          .arrow1 {
            width: 0.42rem;
            height: 0.42rem;
            margin: 0 0 -0.026rem 0.08rem;
          }
          .arrow3 {
            display: none;
            width: 0.32rem;
            height: 0.32rem;
            margin: 0 0 0 0.13rem;
          }
        }
        .btn-15 {
          background: #cbe2ff;
          color: #0044a0;
          border: none;
          z-index: 1;
          border-radius: 20rem;
          overflow: hidden;
          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
            background-color: #0044a0;
            border-radius: 20rem;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #fff;
            background: transparent;

            .arrow1 {
              display: none;
            }
            .arrow3 {
              display: inline-block;
              transition: all 0.5s;
            }
            &:after {
              left: 0;
              width: 100%;
              border-radius: 20rem;
            }
          }
          &:active {
            top: 2px;
          }
        }
      }
    }
    .text-center {
      padding: 2.66rem 1rem !important;
      h1 {
        font-size: 0.96rem;
      }
      p {
        font-size: 0.53rem !important;
      }
      .custom-btn {
        color: #0044a0;
        border-radius: 5px;
        padding: 0.26rem 0.66rem;
        box-sizing: border-box;
        font-family: "Lato", sans-serif;
        font-weight: 500;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s ease;
        position: relative;
        display: inline-block;
        outline: none;
        span {
          font-size: 1rem;
        }
        .arrow3 {
          width: 0.8rem;
          height: 0.8rem;
          margin: 0 0 0 0.13rem;
        }
        .arrow1 {
          display: none;
          width: 0.8rem;
          height: 0.8rem;
          margin: 0 0 -0.053rem 0.08rem;
        }
      }
      .btn-15 {
        background-color: #0044a0;
        color: #fff;
        border: none;
        z-index: 1;
        border-radius: 20rem;
        overflow: hidden;
        border: 1px solid transparent;

        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 100%;
          top: 0;
          right: 0;
          z-index: -1;
          background: #fff;
          border-radius: 20rem;
          transition: all 0.3s ease;
        }
        &:hover {
          color: #0044a0;
          background: transparent;
          border: 1px solid #0044a0;
          .arrow3 {
            display: none;
          }
          .arrow1 {
            display: inline-block;
            transition: all 0.5s;
          }
          &:after {
            left: 0;
            width: 100%;
            border-radius: 20rem;
          }
        }
        &:active {
          top: 2px;
        }
      }
    }
    .card-group {
      flex-direction: column;
      .card-group-item0,
      .card-group-item1,
      .card-group-item2 {
        box-sizing: border-box;
        background-size: 100% 100%;

        border-right: none;
        width: 100%;
        .card-body {
          padding: 1rem;
          height: auto;
          div {
            .h1 {
              font-size: 2rem;
              color: white;
              h4 {
                margin-bottom: 0;
              }
            }
          }
          .card-buttonList {
            margin-top: 0;
            .h5 {
              color: white;
            }
            .card-btn-items {
              .custom-btn {
                padding: 0.26rem 0.66rem;
                background-color: transparent;
                color: #fff;
                border-color: #ffffff !important;
                span {
                  font-size: 0.53rem;
                }
              }
            }
          }
        }
      }
    }
    .cardSwiper {
      .title {
        width: 8.64rem;
        line-height: 0.98rem;
        font-size: 0.5rem;
        margin-bottom: 1rem;
        height: auto;
      }
      .imglist {
        width: 350px;
        padding: 0 1.33rem;
        margin-top: 1.86rem;
        .el-carousel {
          height: auto;
          ::v-deep .el-carousel__container {
            height: 14rem !important;
            .el-carousel__arrow {
              display: block !important;
            }
            .el-carousel__item {
              transform: translateX(0rem) scale(1) !important;
              width: 100%;
              .text {
                height: 4rem;
                left: 2rem;
                p {
                  font-size: 0.8rem;
                }
              }
            }
          }
        }
      }
    }
    .ourCustomers {
      margin-bottom: 7rem;
      .title {
        width: 8.64rem;
        line-height: 0.98rem;
        font-size: 0.5rem;
        margin-bottom: 1rem;
        height: auto;
      }
      .content {
        padding: 0 3.2rem;
        height: 5.33rem;
        .left {
          width: 40%;
          font-size: 0.93rem;
        }
      }
    }
    .es-center-bottom {
      height: 9.93rem;
      .es-bottom-list {
        .es-bottom-item {
          img {
            width: 4.8rem !important;
            height: 4.8rem !important;
          }
        }
      }
    }
    .footerBanner {
      margin-top: 3.73rem;
      height: 12.26rem;
      padding: 2.66rem;
      h1 {
        font-size: 0.85rem;
      }
      .mt-5 {
        margin-top: 1rem !important;
        .custom-btn {
          color: #0044a0;
          border-radius: 5px;
          padding: 0.26rem 0.66rem;
          box-sizing: border-box;
          font-family: "Lato", sans-serif;
          font-weight: 500;
          background: transparent;
          cursor: pointer;
          transition: all 0.3s ease;
          position: relative;
          display: inline-block;
          outline: none;
          span {
            font-size: 1rem;
          }
          .arrow3 {
            width: 0.8rem;
            height: 0.8rem;
            margin: 0 0 0 0.13rem;
          }
          .arrow1 {
            width: 0.8rem;
            height: 0.8rem;
            margin: 0.1rem 0 -0.053rem 0.08rem;
          }
        }
        .btn-15 {
          background-color: #fff;
          color: #0044a0;
          border: none;
          z-index: 1;
          border-radius: 20rem;
          overflow: hidden;
          border: 1px solid transparent;

          &::after {
            position: absolute;
            content: "";
            width: 0;
            height: 100%;
            top: 0;
            right: 0;
            z-index: -1;
            background: #0044a0;
            border-radius: 20rem;
            transition: all 0.3s ease;
          }
          &:hover {
            color: #fff;
            background: transparent;
            border: 1px solid #0044a0;
            .arrow3 {
              display: none;
            }
            .arrow1 {
              display: inline-block;
              transition: all 0.5s;
            }
            &:after {
              left: 0;
              width: 100%;
              border-radius: 20rem;
            }
          }
          &:active {
            top: 0.053rem;
          }
        }
      }
    }
  }
}
</style>
