import axios from 'axios';
const service = axios.create({
    baseURL: `${process.env.VUE_APP_GLOBAL_VARIABLE}/api`, // 设置基础URL
    // baseURL: `${process.env.VUE_APP_GLOBAL_VARIABLE}/api`, // 设置基础URL
    timeout: 10 * 1000, // 设置请求超时时间
    headers: {
        'Content-Type': 'application/json; charset=utf-8',
        // 这里可以添加一些默认请求头信息
    }
});

// 请求拦截器
service.interceptors.request.use(
    config => {
        // 在这里可以做一些请求前的操作，比如设置 token 等
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        return config;
    },
    error => {
        // 处理请求错误
        console.error('Request Error:', error);
        return Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    response => {
        const res = response.data;

        // 这里可以对响应数据做一些处理

        return res;
    },
    error => {
        console.error('Response Error:', error);

        if (error.response && error.response.status === 401) {
            alert("Authentication failed, please login again.");
            // Redirect to login page or do something else according to your auth logic.
        }

        return Promise.reject(error);
    }
);

export default service;