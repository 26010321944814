import { createStore } from 'vuex'
import api from "@/utils/api";

export default createStore({
  state: {
    configData: {},
    searchList:[],
  },
  getters: {
  },
  mutations: {
    setData(state, payload) {
      state.configData = payload;
    },
    setSearchList(state, payload){
      state.searchList = payload;
    }
  },
  actions: {
    async getSearchList({commit},params){
      try {
        const res = await api.get("/common/search", {
          params: {
            keywords: params.keywords,
            page:  params.page,
          },
        });
        commit("setSearchList",res.data);
        console.log(res,'这是vux里的请求')
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  },
  modules: {
  }
})
