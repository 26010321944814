<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script setup lang="ts">
import Header from "@/layout/Header.vue";
import Footer from "@/layout/Footer.vue";
import { defineExpose } from "vue";

defineExpose({
  setMetaTags() {
    document.title = props.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute("content", props.description);
    }
  },
});
</script>


<style lang="scss">
#app {
  font-family: "open Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
